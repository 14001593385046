import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ProjectCard = (props) => {
  return (
    <Card className="project-item"
      sx={{
        maxWidth: 350,
        backgroundColor: "rgba(255, 165, 0, 0.3)",
        color: "white",
        margin: "10px",
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
      }}
    >
      <CardMedia sx={{ height: 140 }} image={props.image} title={props.title} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontFamily="Orbitron"
        >
          {props.title}
        </Typography>
        <Typography variant="body2" color="white">
          {props.description}
        </Typography>
      </CardContent>

     
        <CardActions>
        {props.link && (
          <Button
            size="small"
            variant="contained"
            sx={{
              backgroundColor: "brown",
              "&:hover": {
                backgroundColor: "orange",
              },
            }}
            href={props.link}
            target="_blank"
            style={{ color: "white" }}
          >
            See Project
          </Button>
           )}

           
     
{props.ytLink && (
 
        <a href={props.ytLink} target="_blank" style={{ color: "white", padding: '5px' }}>
          <i class="fab fa-youtube"></i>
        </a>
 


)}

     
{props.figmaLink && (
 
<a href={props.figmaLink} target="_blank" style={{ color: "white", padding: '5px' }}>
  <i class="fab fa-figma"></i>
</a>



)}
           
      {props.githubLink && (
           <a href={props.githubLink} target="_blank" style={{ color: "white",padding:'5px'}}>
             <i class="fab fa-github"></i>
           </a>
   
 
)}
        </CardActions>
     

    </Card>
  );
};

export default ProjectCard;
