import React, { useEffect } from "react";
import "./App.css";
import { Button } from "@mui/material";
import ProjectCard from "./components/projectcard";

const App = () => {
  window.addEventListener("scroll", function () {
    const header = document.querySelector("header");
    let scrollPosition = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    header.style.backgroundPosition = "right " + scrollPosition * 0.2 + "px";
  });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const halo = document.querySelector(".halo");
      halo.style.left = `${e.pageX}px`;
      halo.style.top = `${e.pageY}px`;
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="App">
      <div className="halo"></div>
      <header
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <div style={{ padding: "10px" }}>
          <h1
            style={{
              fontSize: "70px",
              maxLines: 2,
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Ramnick Francis
          </h1>
          <h2>Developer</h2>
          <div>
            <p style={{ maxWidth: "50%" }}>
              Bridging the gap between creating human-centered applications and
              technical development is at the center of my software development
              process.
            </p>
            <br />
            <br />
            <h2 style={{ color: "white", fontFamily: "Arial" }}>
              I am open for work opportunities!
            </h2>
            <a href="mailto:rpramos5@up.edu.ph">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "darkorange",
                  fontWeight: "bold",
                }}
                disableElevation
              >
                Send me an Email!
              </Button>
            </a>
          </div>
        </div>
      </header>

      <div style={{ padding: 10 }}>
        <section id="about">
          <h1>About Me</h1>
          <p>
            With extensive experience in front-end development using frameworks
            like React JS for web and Flutter for mobile, I have honed my skills
            in experimental design and development processes. This passion for
            human-centered design is evident in my leadership roles in some
            projects I partook. In these endeavors, I work passionately with
            both front-end development and UI/UX design teams. Proficiency in
            tools such as Figma enables me to effectively communicate design
            concepts and ideas.
          </p>
          <p>
            In addition to my focus on creating user-friendly applications, I am
            a committed team player with a solution-oriented approach. My
            ability to provide efficient resolutions has consistently improved
            team workflows and improved efficiency with project management tools
            in my teams.
          </p>
          <p style={{ fontWeight: "bold", color: "orange" }}>
            Here are some projects I worked on . . .
          </p>
        </section>

        <section id="projects">
          <div className="projects">
            <ProjectCard
              title="Booking and Reservation for ICS"
              description="Worked for the Frontend Developer Team to create a Room Reservation Management System for the Institute of Computer Science - UPLB using React JS"
              image="assets/images/brics.png"
              link="https://brics-alpha.vercel.app/"
            />

            <ProjectCard
              title="Tulong Los Banos"
              description="Worked on designing the user experience and developing the user interface of a Flutter Mobile
Application while working on the Firebase Integration to serve as a donation management system for Los Banos, Laguna"
              image="assets/images/tulong.jpg"
            />

            <ProjectCard
              title="Elbi Food Review"
              description="Developed a database management system for a Food Review Application in Los Banos, Laguna using
MariaDB and Python"
              image="assets/images/elbifood.png"
              githubLink="https://github.com/rfpramos/elbi-food-review"
            />

            <ProjectCard
              title="Official UPLB February Fair App"
              description="Implemented experimental research and UI/UX design process for pitching a companion application
for the UPLB February Fair using Figma"
              image="assets/images/febfair.png"
              ytLink="https://youtu.be/1EgwotWA99c"
              figmaLink="https://www.figma.com/proto/9rdQQ4diXdr152YVSOCihc/CMSC-173%3A-February-Fair-Donation-App?node-id=1-10&starting-point-node-id=1%3A275&t=F7qJpvI0Agxgqv43-1"
            />

            <ProjectCard
              title="E-mbakan"
              description="Developed an e-commerce website using Material UI through MERN Stack for selling crops and
poultry product"
              image="assets/images/embakan.png"
              ytLink="https://youtu.be/MntXkmDEjrE"
              githubLink="https://github.com/CMSC100-1S2324/project-group-3"
            />
          </div>
        </section>

        <section id="skills">
          <h2>Skills</h2>
          <p>I have experience in the following technologies and tools:</p>
          <ul>
            <li>
              <i class="fas fa-code"></i> HTML, CSS, JavaScript
            </li>
            <li>
              <i class="fab fa-react"></i> React
            </li>
            <li>
              <i class="fab fa-node-js"></i> Node.js, Express
            </li>
            <li>
              <i class="fas fa-database"></i> MongoDB, SQL
            </li>
            <li>
              <i class="fab fa-git-square"></i> Git, GitHub
            </li>
            <li>
              <i class="fas fa-mobile-alt"></i> Responsive Design
            </li>
          </ul>
          <h2>Resume</h2>
          <p>
            For more details about my professional experience, you can view my
            resume here <br></br>
            <i style={{ color: "orange" }}>Last updated on June 18, 2024</i>
          </p>
          <i class="fas fa-file-pdf" style={{ fontSize: "2em" }}></i> &nbsp;
          <a
            style={{
              color: "white",
              fontSize: "2em",
              textDecoration: "none",
              transition: "color 0.2s",
            }}
            href="https://drive.google.com/file/d/1LusirS4SCydjy9DYp0Qh409G7l8DlNHI/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(event) => (event.target.style.color = "orange")}
            onMouseLeave={(event) => (event.target.style.color = "white")}
          >
            &nbsp; Download Resume
          </a>
        </section>
      </div>

      <div>
        <section id="links">
          <h2>Contact me via</h2>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/rfpramos/">
                <i class="fab fa-linkedin"></i> LinkedIn
              </a>
            </li>
            <li>
              <a href="mailto:rpramos5@up.edu.ph">
                <i class="fas fa-envelope"></i> rpramos5@up.edu.ph
              </a>
            </li>

            <li>
              <a>
                <i class="fas fa-comment"></i> <i class="fas fa-phone"></i> +63
                960 277 1720 - <i>Viber and WhatsApp</i>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default App;
